import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adi-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss',
})
export class PopupComponent {
  @Input() title: string | undefined;
  @Input() body: string | undefined;
  @Input() buttonTxt: string | undefined;
  @Input() secondButtonTxt: string | undefined;
  @Output() closePopup = new EventEmitter<void>();
  @Output() revoke = new EventEmitter<void>();
  @Input() showCloseIcon: boolean | undefined;
  @Input() class: string | undefined;

  onBtnClicked() {
    this.closePopup.emit();
  }

  onBtnClick() {
    this.revoke.emit();
  }
}
